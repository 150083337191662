import React, { forwardRef } from 'react';
import { motion } from 'framer-motion';
import { styled } from '../ui/styled';

const Path = props => (
	<motion.path
		fill="transparent"
		strokeWidth="2"
		stroke="currentColor"
		strokeLinecap="round"
		{...props}
	/>
);

const StyledButton = styled(motion.button, {
	appearance: 'none',
	border: 'none',
	backgroundColor: 'transparent',
	color: '$mono90',
	outline: 'none',
	position: 'relative',
	lg: {
		display: 'none',
	},
});

export const MenuToggle = forwardRef((props, ref) => (
	<StyledButton
		{...props}
		ref={ref}
		variants={{
			closed: {},
			open: {},
		}}
	>
		<svg width="42" height="42" viewBox="0 0 42 42">
			<Path
				variants={{
					closed: { d: 'M 6 14 L 36 14' },
					open: { d: 'M 6 28 L 36 14' },
				}}
			/>

			<Path
				variants={{
					closed: { d: 'M 6 28 L 36 28' },
					open: { d: 'M 6 14 L 36 28' },
				}}
			/>
		</svg>
	</StyledButton>
));
