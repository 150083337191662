/**
 * Stitches design system tokens.
 * https://stitches.dev/docs/tokens
 */
export const tokens = {
	fonts: {
		$mono: '"JetBrainsMono", "Courier New", Courier, monospace',
		$sans:
			'MarkOT, system-ui,-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",sans-serif',
	},
	fontWeights: {
		$400: '400',
		$500: '500',
		$700: '700',
	},
	fontSizes: {
		$3: '12px',
		$3s: '14px',
		$4: '16px',
		$4s: '18px',
		$5: '20px',
		$5s: '21px',
		$6: '24px',
		$7: '28px',
		$8: '32px',
		$9: '36px',
		$10: '40px',
		$11: '44px',
		$12: '48px',
		$16: '64px',
	},
	lineHeights: {
		$1: '1.2',
		$2: '1.3',
		$3: '1.42',
		$5: '1.54',
		$6: '1.636',
	},
	colors: {
		$transparent: 'transparent',
		$current: 'currentColor',
		$mono0: '#040404',
		$mono10: '#121212',
		$mono20: '#303030',
		$mono30: '#5E5E5E',
		$mono40: '#878787',
		$mono50: '#AAAAAA',
		$mono60: '#C5C5C5',
		$mono70: '#D9D9D9',
		$mono80: '#E9E9E9',
		$mono90: '#F4F4F4',
		$mono100: '#FFFFFF',
		$accent: '#F5DD01',
	},
	space: {
		$px: '1px',
		$semi: '2px',
		$vh: '100vh',
		$full: '100%',
		$0: '0',
		$1: '4px',
		$2: '8px',
		$3: '12px',
		$4: '16px',
		$5: '20px',
		$6: '24px',
		$7: '28px',
		$8: '32px',
		$9: '36px',
		$10: '40px',
		$12: '48px',
		$14: '56px',
		$16: '64px',
		$17: '68px',
		$18: '72px',
		$20: '80px',
		$21: '84px',
		$22: '88px',
		$24: '96px',
		$28: '112px',
		$36: '144px',
		$42: '168px',
		$48: '192px',
	},
	sizes: {
		$px: '1px',
		$semi: '2px',
		$vh: '100vh',
		$full: '100%',
		$0: '0',
		$1: '4px',
		$2: '8px',
		$3: '12px',
		$4: '16px',
		$5: '20px',
		$6: '24px',
		$7: '28px',
		$8: '32px',
		$9: '36px',
		$10: '40px',
		$12: '48px',
		$14: '56px',
		$16: '64px',
		$17: '68px',
		$18: '72px',
		$20: '80px',
		$21: '84px',
		$22: '88px',
		$24: '96px',
		$28: '112px',
		$36: '144px',
		$42: '168px',
		$48: '192px',
	},
};
