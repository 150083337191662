import { styled } from './styled';

export const StyledLayout = styled('div', {
	minHeight: '$vh',
});

export const StyledAppHeader = styled('header', {
	display: 'block',
	borderBottom: '1px solid $mono30',
	width: '100%',
	height: '$16',
	position: 'fixed',
	zIndex: 999,
	backgroundColor: '$mono10',
	lg: {
		top: '$0',
		left: '$0',
		height: '$vh',
		display: 'block',
		width: '16rem',
		backgroundColor: 'transparent',
		borderRight: '1px solid $mono30',
		borderBottom: 'none',
	},
});

export const StyledMain = styled('main', {});

export const StyledArticle = styled('article', {
	paddingTop: '$36',
	paddingBottom: '$20',
	width: '100%',
	md: {
		width: '75%',
	},
	lg: {
		paddingTop: '$42',
		paddingBottom: '$20',
		width: '100%',
	},
});

export const StyledArticleHeader = styled('header', {
	marginBottom: '$16',
	paddingBottom: '$16',
	borderBottom: '1px solid',
	borderColor: '$mono30',
	display: 'grid',
	rowGap: '$4',
	gridColumn: '1 / span 10',
	md: {
		marginBottom: '$20',
		paddingBottom: '$20',
		rowGap: '$4',
	},
	lg: {
		rowGap: '$4',
	},
});

export const StyledContainer = styled('div', {
	marginLeft: 'auto',
	marginRight: 'auto',
	maxWidth: '100%',
	width: '640px',
	padding: '0 $4',
	md: {
		padding: '0 $10 0 $8',
		width: '742px',
	},
	lg: {
		padding: '0 $6',
		width: '742px',
	},
});

export const StyledArticleFlow = styled('div', {
	'> h1': {
		margin: '$16 0 $6 0',
		lg: {
			margin: '$20 0 $7 0',
		},
	},
	'> h2': {
		margin: '$16 0 $6 0',
		lg: {
			margin: '$20 0 $7 0',
		},
	},
	'> h3': {
		margin: '$14 0 $6 0',
		lg: {
			margin: '$18 0 $7 0',
		},
	},
	'> h4': {
		margin: '$14 0 $6 0',
		lg: {
			margin: '$18 0 $7 0',
		},
	},
	'> h5': {
		margin: '$10 0 $6 0',
		lg: {
			margin: '$12 0 $7 0',
		},
	},
	'> hr': {
		margin: '$17 0 $16 0',
		lg: {
			margin: '$21 0 $20 0',
		},
	},
	'> h6': {
		margin: '$6 0',
		lg: {
			margin: '$7 0',
		},
	},
	'> ol': {
		margin: '$6 0',
		lg: {
			margin: '$7 0',
		},
	},
	'> ul': {
		margin: '$6 0',
		lg: {
			margin: '$7 0',
		},
	},
	'> p': {
		margin: '$6 0',
		lg: {
			margin: '$7 0',
		},
	},
	// display: 'grid',
	// rowGap: '$5',
	// md: {
	// 	rowGap: '$5',
	// },
	// lg: {
	// 	rowGap: '$6',
	// },
});

export const StyledBlock = styled('div', {
	position: 'relative',
	paddingTop: '$px',
	paddingBottom: '$1',
	variants: {
		bleed: {
			true: {
				marginLeft: '-$4',
				marginRight: '-$4',
				md: {
					marginLeft: '-$6',
					marginRight: '-$6',
				},
				lg: {
					marginLeft: '-$8',
					marginRight: '-$8',
				},
			},
		},
	},
});

export const StyledToc = styled('aside', {
	display: 'none',
	md: {
		position: 'fixed',
		display: 'block',
		paddingLeft: '$1',
		top: '$0',
		height: '$vh',
		right: '$0',
		overflow: 'hidden',
		width: '16rem',
	},
});

export const StyledTocScroll = styled('div', {
	overflow: 'scroll',
	position: 'absolute',
	top: 0,
	bottom: 0,
	right: '$6',
	left: 0,
	display: 'flex',
});
