import React from 'react';

import { StyledLayout, StyledAppHeader } from '../ui/layout';
import { AppHeader } from '../containers/app-header';
import '../ui/global';

export const wrapPageElement = ({ element }) => {
	return (
		<>
			<StyledLayout>
				<StyledAppHeader>
					<AppHeader />
				</StyledAppHeader>
				{element}
			</StyledLayout>
		</>
	);
};
