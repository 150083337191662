import React, { forwardRef } from 'react';

import { StyledLogoLink } from '../ui/app-nav';

export const Logo = forwardRef((props, ref) => {
	return (
		<StyledLogoLink
			ref={ref}
			tabIndex={0}
			to="/"
			aria-label={'Pilot Handbook'}
		>
			<svg
				xmlns="http://www.w3.org/2000/svg"
				viewBox="0 0 430 430"
				{...props}
			>
				<path
					d="M178.65 258.63h12.97v-55.29l-12.97 3.47zM185.22 180.73c-4.29 0-7.65 3.4-7.65 7.74 0 4.36 3.29 7.65 7.65 7.65 4.34 0 7.74-3.36 7.74-7.65 0-4.34-3.39-7.74-7.74-7.74zM203.54 153.5v105.13h12.98V150.02zM328.1 215.91v-12.67h-17.94v-17.06l-12.97 3.47v13.59H286.7v12.67h10.48v21.58c0 13.83 6.78 21.14 19.6 21.14h11.31v-12.18l-10.33-.09c-4.91 0-7.61-3.23-7.61-9.09v-21.36h17.95zM255.3 201.87c-15.65 0-28.38 12.73-28.38 28.38 0 15.65 12.73 28.38 28.38 28.38 15.65 0 28.38-12.73 28.38-28.38 0-15.65-12.73-28.38-28.38-28.38zm.01 44.49c-8.89 0-16.11-7.23-16.11-16.12s7.23-16.11 16.11-16.11c8.89 0 16.11 7.23 16.11 16.11 0 8.89-7.22 16.12-16.11 16.12zM139.59 201.87a27.96 27.96 0 00-16.03 5v-3.57h-12.98v93.81h12.98v-43.47c4.75 3.27 10.26 5 16.03 5 15.65 0 28.38-12.73 28.38-28.38 0-15.66-12.73-28.39-28.38-28.39zm.01 44.49c-8.34 0-15.39-6.51-16.05-14.76v-2.64c.66-8.31 7.71-14.82 16.05-14.82 8.89 0 16.11 7.23 16.11 16.11 0 8.88-7.22 16.11-16.11 16.11z"
					fill="currentColor"
				/>
				<path
					d="M214.42 49.48c-106.66 0-193.44 86.77-193.44 193.43 0 50.78 20.61 100.57 56.55 136.62l.3.3 14.77-3.95-.96-.89c-36.59-34.05-57.58-82.19-57.58-132.06 0-99.45 80.91-180.36 180.36-180.36 99.45 0 180.36 80.91 180.36 180.36 0 18.05-2.67 35.87-7.94 52.96l-.39 1.26 14.79-3.96.11-.41c4.32-16.16 6.52-32.94 6.52-49.85-.01-106.68-86.79-193.45-193.45-193.45z"
					fill="currentColor"
				/>
			</svg>
		</StyledLogoLink>
	);
});
