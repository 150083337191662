import { styled } from './styled';

/**
 * exports `Stack` layout primitive, a basic flex box
 * implementation, that applies equal spacing between
 * it's childen.
 */
export const Stack = styled('div', {
	display: 'flex',
	flexDirection: 'column',
	width: '$full',
	variants: {
		direction: {
			row: {
				flexDirection: 'row',
			},
			rowrev: {
				flexDirection: 'row-reverse',
			},
			col: {
				flexDirection: 'column',
			},
		},
		justify: {
			start: {
				justifyContent: 'flex-start',
			},
			center: {
				justifyContent: 'center',
			},
			between: {
				justifyContent: 'space-between',
			},
			end: {
				justifyContent: 'flex-end',
			},
		},
		align: {
			start: {
				alignItems: 'flex-start',
			},
			center: {
				alignItems: 'center',
			},
			between: {
				alignItems: 'space-between',
			},
			end: {
				alignItems: 'flex-end',
			},
		},
		gap: {
			semi: {
				'> * + *': {
					marginTop: '$semi',
				},
			},
			0: {
				'> * + *': {
					marginTop: '$0',
				},
			},
			1: {
				'> * + *': {
					marginTop: '$1',
				},
			},
			2: {
				'> * + *': {
					marginTop: '$2',
				},
			},
			3: {
				'> * + *': {
					marginTop: '$3',
				},
			},
			4: {
				'> * + *': {
					marginTop: '$4',
				},
			},
			5: {
				'> * + *': {
					marginTop: '$5',
				},
			},
			6: {
				'> * + *': {
					marginTop: '$6',
				},
			},
			7: {
				'> * + *': {
					marginTop: '$7',
				},
			},
			8: {
				'> * + *': {
					marginTop: '$8',
				},
			},
			9: {
				'> * + *': {
					marginTop: '$9',
				},
			},
			10: {
				'> * + *': {
					marginTop: '$10',
				},
			},
			12: {
				'> * + *': {
					marginTop: '$12',
				},
			},
			16: {
				'> * + *': {
					marginTop: '$16',
				},
			},
			20: {
				'> * + *': {
					marginTop: '$20',
				},
			},
			24: {
				'> * + *': {
					marginTop: '$24',
				},
			},
			28: {
				'> * + *': {
					marginTop: '$28',
				},
			},
			36: {
				'> * + *': {
					marginTop: '$36',
				},
			},
			42: {
				'> * + *': {
					marginTop: '$42',
				},
			},
			48: {
				'> * + *': {
					marginTop: '$48',
				},
			},
		},
	},
});
