import { css } from './styled';

css.global({
	body: {
		margin: '0',
		fontFamily: '$sans',
		backgroundColor: '$mono10',
		color: '$mono90',
		lineHeight: '$6',
	},
	a: {
		display: 'inline-block',
		color: 'inherit',
		textDecoration: 'none',
		position: 'relative',

		'&:hover': {
			color: '$accent',
		},
		'&:focus-visible': {
			outline: '1px solid',
			outlineColor: '$mono90',
			outlineOffset: '3px',
		},
		'&:after': {
			content: '""',
			display: 'block',
			position: 'absolute',
			top: 0,
			bottom: 0,
			left: 0,
			right: 0,
			background: 'transparent',
		},
	},
	'.active': {
		color: '$accent',
	},
});
