import React, { useState, useCallback, useRef } from 'react';
import { useMedia } from 'use-media';
import { FocusOn } from 'react-focus-on';

import { Stack } from '../ui/stack';

import { StyledLink } from '../ui/interactive';
import {
	StyledNav,
	StyledNavMenu,
	StyledNavHeading,
	StyledNavList,
	StyledNavListItem,
} from '../ui/app-nav';
import { Logo } from '../ui/logo';

import { MenuToggle } from './menu-toggle';

export const AppNav = ({ sections }) => {
	const [navOpen, setNavOpen] = useState(false);
	const isLargeBreakpoint = useMedia('(min-width: 84rem)');
	const logoRef = useRef();
	const toggleRef = useRef();

	const handleNavToggleClick = useCallback(() => {
		setNavOpen(!navOpen);
	}, [navOpen, setNavOpen]);

	const handleCloseNav = useCallback(() => {
		setNavOpen(false);
	}, [setNavOpen]);

	const shouldNavBeOpen = navOpen && !isLargeBreakpoint;

	return (
		<StyledNav
			initial={false}
			animate={shouldNavBeOpen ? 'open' : 'closed'}
		>
			<div>
				<Stack
					direction="row"
					justify="between"
					css={{
						zIndex: 100,
						position: 'relative',
						lg: {
							padding: '0 $6',
						},
					}}
				>
					<Logo ref={logoRef} />
					<MenuToggle
						onClick={handleNavToggleClick}
						open={navOpen}
						ref={toggleRef}
						aria-controls="app-nav"
						aria-expanded={navOpen}
					/>
				</Stack>
				<FocusOn
					onClickOutside={handleCloseNav}
					onEscapeKey={handleCloseNav}
					shards={[logoRef, toggleRef]}
					enabled={shouldNavBeOpen}
				>
					<StyledNavMenu open={navOpen} id="app-nav">
						{sections.map(c => (
							<NavSection {...c} key={c.id} />
						))}
					</StyledNavMenu>
				</FocusOn>
			</div>
		</StyledNav>
	);
};

const NavSection = props => {
	return (
		<>
			<StyledNavHeading aria-hidden>{props.title}</StyledNavHeading>

			<StyledNavList role="menu" aria-label={props.title}>
				{props.links &&
					props.links.map(link =>
						!link.published ? null : (
							<StyledNavListItem role="none" key={link.slug}>
								<StyledLink
									role="menuitem"
									to={`${link.slug}`}
									activeClassName="active"
								>
									{link.label}
								</StyledLink>
							</StyledNavListItem>
						)
					)}
			</StyledNavList>
		</>
	);
};
