import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import { AppNav } from '../components/app-nav';

const postNavQuery = graphql`
	query {
		mdx(fields: { path: { eq: "sidenav" } }) {
			fields {
				sections {
					title
					links {
						label
						slug
						published
					}
				}
			}
		}
	}
`;

export const AppHeader = () => {
	const data = useStaticQuery(postNavQuery);
	return <AppNav sections={data.mdx.fields.sections} />;
};
