import React from 'react';
import { Link } from 'gatsby';

import { styled } from '../ui/styled';

const StyledAnchor = styled('a', {
	textDecoration: 'none',
	color: '$accent',
	'&:hover': {
		color: '$mono90',
		textDecoration: 'underline',
		textDecorationThickness: 'from-font',
	},
});

export const Anchor = props => {
	let target = '';
	if (props.href.startsWith('http')) {
		target = 'new';
	}

	return <StyledAnchor {...props} target={target} />;
};

export const StyledLink = styled(Link, {});
