import { styled } from './styled';
import { motion } from 'framer-motion';
import { Link } from 'gatsby';

export const StyledNavHeading = styled('h3', {
	fontFamily: '$sans',
	fontWeight: '$600',
	textTransform: 'uppercase',
	fontSize: '$3',
	color: '$mono50',
	marginBottom: '$2',
});

export const StyledNav = styled(motion.nav, {
	padding: '0 $4',
	display: 'flex',
	alignItems: 'center',
	height: '$16',
	position: 'relative',
	zIndex: 100,
	'& > div ': {
		width: '100%',
	},
	lg: {
		padding: '0',
		display: 'block',
		height: '$vh',
		overflow: 'scroll',
		'& > div ': {
			padding: '$7 0 $18 0',
		},
	},
});

export const StyledNavMenu = styled('div', {
	position: 'absolute',
	top: 0,
	right: 0,
	zIndex: 50,
	overflow: 'scroll',
	height: '$vh',
	width: '18rem',
	padding: '$20 $6',
	backgroundColor: '$mono10',
	borderLeft: '1px solid $mono30',
	display: 'none',

	lg: {
		display: 'block',
		position: 'relative',
		padding: '$9 $6 0 $6',
		width: 'auto',
		marginTop: '$8',
		border: 'none',
		zIndex: 'auto',
		height: 'auto',
		overflow: 'initial',
		backgroundColor: 'transparent',
	},

	variants: {
		open: {
			true: {
				display: 'block',
			},
		},
	},
});

export const StyledLogoLink = styled(Link, {
	color: '$mono90',
	width: '$12',
	lg: {
		marginLeft: '-$2',
		width: '$22',
	},
	'&:focus': {
		outline: 'none',
	},
});

export const StyledNavList = styled('ul', {
	listStyle: 'none',
	marginBottom: '$8',
	marginTop: '$0',
	padding: 0,
	'> * + *': {
		marginTop: '$semi',
		md: {
			marginTop: '$semi',
		},
		lg: {
			marginTop: '$semi',
		},
	},
});

export const StyledNavListItem = styled('li', {
	fontFamily: '$sans',
	fontWeight: '$500',
	fontSize: '$4',
});
